
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import EventsDayList from '@/modules/events/components/events-day-list.vue';
import { Inject } from 'inversify-props';
import { Component, Vue, Prop } from 'vue-property-decorator';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import EventsModel from '../../models/events.model';
import EventGroup from '../../interfaces/event-group.enum';

@Component({
    components: {
        ModalWrapper,
        LoaderWrapper,
        PopupEventsContainer,
        EventsDayList,
    },
})

export default class EventsDayPopup extends Vue {
    @Inject(EventsManagerServiceS)
    eventsManagerService!: EventsManagerService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Prop({ required: true })
    private day!: number;

    get holidayEvents() {
        return this.filterArray(this.monthEvents?.filter(event => event.group === EventGroup.HOLIDAY) || []);
    }

    get ignoredOnly() {
        return this.$route.query.showIgnored === 'true';
    }

    get myEvents() {
        return this.monthEvents.filter(event => event.group === EventGroup.MAIN) || [];
    }

    get marketEvents() {
        const { hotelId } = this.$route.params;
        if (!hotelId) return []; // NOTE suggested events should be shown only for hotel

        return this.monthEvents.filter(event => event.group === EventGroup.MARKET) || [];
    }

    get chainEvents() {
        return this.monthEvents.filter(event => event.group === EventGroup.CHAIN) || [];
    }

    get monthEvents() {
        const { day } = this;
        const { year, month } = this.documentFiltersService;
        const date = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
        const collection = this.eventsManagerService.getEventCollection(date, +this.$route.params.hotelId);

        return this.eventsManagerService.getEventsByCollection(collection, this.$route.query.showIgnored === 'true');
    }

    private filterArray(arr: EventsModel[]) {
        const { countries } = this.eventsManagerService.storeState.settings;
        return arr.filter(event => countries.includes(event.country!));
    }

    closeModal() {
        const targetName = this.$route.name!.split('.');
        targetName.pop();

        this.$router.push({
            name: targetName.join('.'),
            params: {
                ...this.$route.params,
            },
            query: this.$route.query,
        });
    }
}
